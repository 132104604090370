import {
    InstallPromptIOS as SourceInstallPromptIOS
} from 'SourceComponent/InstallPromptIOS/InstallPromptIOS.component';

import './InstallPromptIOS.override.style';

/** @namespace Scandipwa/Component/InstallPromptIOS/Component */
export class InstallPromptIOSComponent extends SourceInstallPromptIOS {
    renderContent() {
        return (
            <div block="InstallPromptIOS" elem="Content">
                <div block="InstallPromptIOS" elem="Add">
                    <p>{ __('Tap:') }</p>
                    <p block="InstallPromptIOS" elem="Share" />
                    <p>{ __(', then') }</p>
                </div>
                <div block="InstallPromptIOS" elem="Add">
                    <p block="InstallPromptIOS" elem="Plus" />
                    <p>{ __('Add to Home Screen') }</p>
                </div>
            </div>
        );
    }
}

export default InstallPromptIOSComponent;
