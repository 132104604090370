import {
    CmsPageQuery as SourceCmsPageQuery
} from 'SourceQuery/CmsPage.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/CmsPage/Query */
export class CmsPageQuery extends SourceCmsPageQuery {
    _getPageFields() {
        return [
            'title',
            'content',
            'page_width',
            'content_heading',
            'meta_title',
            'meta_description',
            'meta_keywords',
            this._getAlternativeCmsPageUrls()
        ];
    }

    _getAlternativeCmsPageUrls() {
        return new Field('alternative_cms_page_urls')
            .addFieldList([
                'page_id',
                'store_code',
                'url'
            ]);
    }
}

export default new CmsPageQuery();
