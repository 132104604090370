import React from 'react';
import { connect } from 'react-redux';

import InstallPrompt from 'Component/InstallPrompt';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import CmsPage from 'Route/CmsPage';
import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceRoute/HomePage/HomePage.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    componentDidMount() {
        const { changeHeaderState } = this.props;

        window.scrollTo(0, 0);

        changeHeaderState({
            name: DEFAULT_STATE_NAME,
            isHiddenOnMobile: false
        });
    }

    render() {
        return (
            <div block="HomePage">
                <InstallPrompt />
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */ }
                <CmsPage { ...this.props } />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
