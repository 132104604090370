import NoMatch from 'Route/NoMatch';
import {
    CmsPage as SourceCmsPage
} from 'SourceRoute/CmsPage/CmsPage.component';

/** @namespace Scandipwa/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPage {
    render() {
        const {
            page,
            isBreadcrumbsActive,
            isLoading,
            isPageLoaded
        } = this.props;

        const { page_width } = page;

        if (!isLoading && !isPageLoaded) {
            return <NoMatch />;
        }

        return (
            <main
              block="CmsPage"
              mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
            >
                <div block="CmsPage" elem="Wrapper" mods={ { page_width } }>
                    <div block="CmsPage" elem="Content">
                        { this.renderContent() }
                    </div>
                </div>
            </main>
        );
    }
}

export default CmsPageComponent;
