import {
    InstallPromptAndroid as SourceInstallPromptAndroid
} from 'SourceComponent/InstallPromptAndroid/InstallPromptAndroid.component';

import './InstallPromptAndroid.style';

/** @namespace Scandipwa/Component/InstallPromptAndroid/Component */
export class InstallPromptAndroidComponent extends SourceInstallPromptAndroid {
    render() {
        return (
            <div block="InstallPromptAndroid">
                <div block="InstallPromptAndroid" elem="Wrapper">
                    { this.renderCloseButton() }
                    { this.renderContent() }
                    { this.renderInstallButton() }
                </div>
            </div>
        );
    }
}

export default InstallPromptAndroidComponent;
