import {
    ANIMATION_DURATION,
    ERROR_TYPE
} from 'SourceComponent/Notification/Notification.config';

export {
    ANIMATION_DURATION,
    ERROR_TYPE
};

export const NOTIFICATION_LIFETIME = 4000;
export const ERROR_NOTIFICATION_LIFETIME = 4000;
