import { LOADING_TIME } from '@scandipwa/scandipwa/src/route/CmsPage/CmsPage.config';
import { connect } from 'react-redux';

import { CMS_PAGE } from 'Component/Header/Header.config';
import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceRoute/CmsPage/CmsPage.container';
import { debounce } from 'Util/Request';
import { isHomePageUrl } from 'Util/Url';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Route/CmsPage/Container */
export class CmsPageContainer extends SourceCmsPageContainer {
    onPageLoad = ({ cmsPage: page }) => {
        const {
            location: { pathname },
            updateMeta,
            setHeaderState,
            updateBreadcrumbs
        } = this.props;

        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords,
            alternative_cms_page_urls
        } = page;

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(page);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
            alternatives: alternative_cms_page_urls
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack()
            });
        }

        this.setState({ page, isLoading: false, isPageLoaded: true });
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
